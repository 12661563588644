"use strict";
/*!
	Author: Jakub Kordovský
	Version: 2.0
*/

class SwipeView {
	constructor(context, options = {}) {
		const defaults = {
			classPrefix: "swipeview",
		};

		this.options = { ...defaults, ...options };
		this.context = context;
		this.initialiaze();
		this.opened = false;
	}

	initialiaze() {
		if (this.initialiazed) return;

		this.currentIndex = 0;
		//this.lastFocusedElement = null;
		this.instanceId = `swipeview-${Date.now()}`;

		this.slides = Array.from(
			document.querySelectorAll(`[data-swipeview="${this.context}"]`)
		);

		this.amount = this.slides.length;
		this.items = [];
		this.images = [];

		if (this.amount === 0) return;

		this._createStructure();
		this._populateGallery();

		this.container.id = this.instanceId;
		//this.container.tabIndex = "-1";
		//this.container.setAttribute("role", "listbox");

		window.addEventListener("keydown", ({ code }) => {
			//console.log(code);
			if (!this.container.open) return;

			if (code === "Escape") {
				this.close();
			}

			if (!this.items.includes(document.activeElement)) {
				if (code === "ArrowRight") {
					this.slideTo(this.currentIndex + 1);
				} else if (code === "ArrowLeft") {
					this.slideTo(this.currentIndex - 1);
				}
			}
		});

		window.addEventListener("resize", () => {
			if (this.container.open) {
				this.slideTo(this.currentIndex);
			}
		});

		this.itemClass = `.${this.options.classPrefix}__item`;
		this.currentIndex = 0;
		this.listwrapper.style.setProperty(
			"--current-image",
			`url('${this.items[0].dataset.image}')`
		);
		this.slideTo(this.currentIndex);
		this.initialiazed = true;
		this.setCounter(1);
		this.nextButton.disabled = 1 === this.amount;
		this.previousButton.disabled = true;

		this.observer = new IntersectionObserver(
			(entries) => {
				const intersecting = entries.filter(
					(entry) => entry.intersectionRatio > 0.5
				);

				if (intersecting.length > 0) {
					const index = this.items.indexOf(intersecting[0].target);
					this.currentIndex = index;
					this.listwrapper.style.setProperty(
						"--current-image",
						`url('${this.items[this.currentIndex].dataset.image}')`
					);
					this.setCounter(index + 1);
					this.nextButton.disabled = index + 1 === this.amount;
					this.previousButton.disabled = index === 0;
				}
			},
			{
				root: null,
				rootMargin: "0px",
				threshold: "0.5",
			}
		);
	}

	setCounter(amount) {
		this.counter.textContent = amount;
		this.counterWrapper.dataset.count = amount;

		this.items.forEach((item) => {
			item.setAttribute("aria-selected", "false");
			item.tabIndex = "-1";
		});

		this.items[this.currentIndex].setAttribute("aria-selected", "true");
		this.items[this.currentIndex].tabIndex = "0";
	}

	_createStructure() {
		this.container = document.createElement("dialog");
		this.listwrapper = document.createElement("div");
		this.list = document.createElement("ul");
		this.counterWrapper = document.createElement("span");
		this.counter = document.createElement("span");

		this.exitButton = document.createElement("button");
		this.previousButton = document.createElement("button");
		this.nextButton = document.createElement("button");

		this.container.classList = this.options.classPrefix;
		this.listwrapper.classList.add(
			`${this.options.classPrefix}__list-wrapper`
		);

		this.list.setAttribute("role", "listbox");
		//this.list.tabIndex = "-1";
		this.list.style.width = `${this.total * 100}vw`;
		this.list.classList.add(`${this.options.classPrefix}__list`);

		this.counterWrapper.classList.add(
			`${this.options.classPrefix}__counter-wrapper`
		);
		this.counter.classList.add(`${this.options.classPrefix}__counter`);

		this.nextButton.type = "button";
		this.nextButton.setAttribute("aria-controls", this.instanceId);
		this.nextButton.setAttribute("aria-label", "Další obrázek");
		this.nextButton.classList.add(
			`${this.options.classPrefix}__button`,
			`${this.options.classPrefix}__button--next`
		);

		this.nextButton.disabled = this.amount === 1;

		this.previousButton.type = "button";
		this.previousButton.setAttribute("aria-controls", this.instanceId);
		this.previousButton.setAttribute("aria-label", "Předchozí obrázek");
		this.previousButton.classList.add(
			`${this.options.classPrefix}__button`,
			`${this.options.classPrefix}__button--previous`
		);

		this.previousButton.disabled = this.amount === 1;

		this.exitButton.type = "button";
		this.exitButton.setAttribute("aria-controls", this.instanceId);
		this.exitButton.setAttribute("aria-label", "Zavřít galerii");
		this.exitButton.classList.add(
			`${this.options.classPrefix}__button`,
			`${this.options.classPrefix}__button--exit`
		);

		this.counterWrapper.textContent = `/${this.slides.length}`;
		this.counterWrapper.insertAdjacentElement("afterbegin", this.counter);

		this.listwrapper.insertAdjacentElement("beforeend", this.list);
		this.container.append(
			this.listwrapper,
			this.counterWrapper,
			this.previousButton,
			this.nextButton,
			this.exitButton
		);

		document.body.insertAdjacentElement("beforeend", this.container);

		// const focusableElements = Array.from(
		// 	this.container.querySelectorAll("button")
		// );

		// this.firstFocusable = focusableElements.shift();
		// this.lastFocusable = focusableElements.pop();

		this.exitButton.addEventListener("click", () => {
			this.close();
		});
		this.nextButton.addEventListener("click", () => {
			this.slideTo(this.currentIndex + 1);
		});
		this.previousButton.addEventListener("click", () => {
			this.slideTo(this.currentIndex - 1);
		});
	}

	_populateGallery() {
		let item;

		this.slides.forEach((element, index) => {
			item = document.createElement("li");
			item.classList.add(`${this.options.classPrefix}__item`);
			item.dataset.index = index;
			item.dataset.image = element.dataset.swipeviewSource;

			const image = new Image();
			image.loading = "lazy";
			image.classList.add(`${this.options.classPrefix}__image`);
			image.src = element.dataset.swipeviewSource;

			if (element.dataset.width) {
				image.width = element.dataset.width;
			}

			if (element.dataset.height) {
				image.height = element.dataset.height;
			}

			//item.style.backgroundImage = `url(${element.dataset.swipeviewSource})`;
			//item.style.backgroundSize = "contain";
			//item.style.backgroundPosition = "50% 50%";

			item.append(image);
			this.images.push(image);

			//item.tabIndex = "-1";
			item.setAttribute("aria-selected", "false");
			item.setAttribute("aria-label", element.dataset.swipeviewName);
			this.list.append(item);

			element.setAttribute("aria-controls", this.instanceId);
			element.addEventListener("click", (event) => {
				event.preventDefault();
				//this.lastFocusedElement = element;
				this.slideTo(index, false).open();
			});

			this.items.push(item);
		});

		this.listwrapper.addEventListener(
			"click",
			(event) => {
				if (event.target.nodeName !== "IMG") {
					this.close();
				}
			},
			false
		);

		item = null;
	}

	next() {
		this.slideTo(this.currentIndex + 1);
	}

	previous() {
		this.slideTo(this.currentIndex - 1);
	}

	slideTo(index) {
		this.nextButton.disabled = index === this.amount - 1;
		this.previousButton.disabled = index === 0;

		if (index < 0 || index >= this.amount) {
			return this;
		}

		if (index >= 0 && index < this.amount) {
			this.currentIndex = index;
			this.items[this.currentIndex].scrollIntoView({ behavior: "smooth" });
		}

		return this;
	}

	open() {
		document.documentElement.style.overflow = "hidden";
		//this.container.open = true;
		if (!this.opened) {
			this.opened = true;
			this.images.forEach((image) => {
				image.loading = "eager";
			});
		}

		this.container.showModal();
		//this.container.dataset.isOpen = this.container.open.toString();

		this.items.forEach((item) => {
			this.observer.observe(item);
		});

		this.items[this.currentIndex].scrollIntoView({ behavior: "instant" });
		this.items[this.currentIndex].focus();
		//console.log("focusing", this.items[this.currentIndex]);
		return this;
	}

	close() {
		document.documentElement.style.overflow = "";
		this.container.close();
		//this.container.removeAttribute("aria-activedescendant");

		this.items.forEach((item) => {
			this.observer.unobserve(item);
		});

		return this;
	}
}
